import toast from "react-hot-toast";
import { STAKING_MASTERCHEF_ABI } from "../Config/abi/stakeAbi";
import { CHAINS } from "../Config/env";
import { getWalletAddress } from "../Lib/localStorage";
import { EditAllocationHooks } from "./Usebackendaxios";
import { GetChainIndex, useWeb3 } from "./useWeb3";
// import { toFixedNumber } from "../../../Frontend/src/Hooks/useContract";
// import { iconTheme, position, style } from "../../../Frontend/src/Hooks/useToast";

export const UseStakingAddress = async () => {
    try {
        const web3 = await useWeb3();
        const contract = new web3.eth.Contract(STAKING_MASTERCHEF_ABI, getStakingAddress());

        return contract;
    } catch (e) {

    }
}


export const getStakingAddress = () => {
    try {
        const index = GetChainIndex();
        const ContractAddress = CHAINS[index].LOCKEDSTAKING;

        return ContractAddress;
    } catch (e) {

    }
}
export const toFixedNumber = (x) => {
    if (Math.abs(x) < 1.0) {
        var e = parseInt(x.toString().split('e-')[1]);
        if (e) {
            x *= Math.pow(10, e - 1);
            x = '0.' + (new Array(e)).join('0') + x.toString().substring(2);
        }
    } else {
        var e = parseInt(x.toString().split('+')[1]);
        if (e > 20) {
            e -= 20;
            x /= Math.pow(10, e);
            x += (new Array(e + 1)).join('0');
        }
    }
    return x;
}


export const AddTier = async(data , formdata) => {
    try{console.log("Addtier" , data);
        let stakecontract = await UseStakingAddress();
        console.log("stakecontract?.methods" , data?.pid?.toString() ,(parseFloat(data?.min)*10**18?.toString()) ,( parseFloat(data?.max)*10**18)?.toString() , (parseFloat(data?.percentage)*10**18 )?.toString(), data?.name);
        let addtier =  stakecontract?.methods?.setTier(data?.pid?.toString() ,toFixedNumber(parseFloat(data?.min)*10**18?.toString()) ,toFixedNumber( parseFloat(data?.max)*10**18)?.toString() , toFixedNumber(parseFloat(data?.percentage)*10**18 )?.toString(), data?.name).send({ from: getWalletAddress() });
        await toast.promise(data, {
            loading: 'Set Tier...',
            success: 'Set Tier Successfully',
            error: 'Error ! When Set Tier',
        });
        
        let result = await EditAllocationHooks(formdata);
        if(result?.data?.type == "Success"){
            // navigate.push("/allocationlist");
            window.location.href = window.location.origin + "/allocationlist"
        }
        return result;
    }
    catch(e){
        console.log("Error on add tier" , e);
    }
}